<template>
  <transition name="dialog-fade">
    <!-- @click.self="handleClose()" -->
    <div class="dialog__wrapper">
      <div class="dialog">
        <div class="dialog__header">
          <slot name="title">
            <span class="dialog__title">{{ title }}</span>
          </slot>
          <img src="@/assets/dialog_close.png" class="icon-close" @click="handleClose()">
          <!-- <button class="dialog__headerbtn" >
            <i class="icon-close">×</i>
          </button> -->
        </div>
        <div class="dialog__body">
          <slot>
            <span>...</span>
          </slot>
        </div>
        <div class="dialog__footer" v-if="footVisible">
          <slot name="footer">
            <span v-show="showClose" @click="handleClose()" v-domPreventReClick>{{ closeTitle }}</span>
            <span v-show="showOk" @click="handleOk()" v-domPreventReClick>{{ okTitle }}</span>
            <!-- <el-button
              @click="handleOk()"
              type="primary"
              v-preventReClick
              size="large"
              style="margin-right:60px"
              >{{ okTitle }}</el-button
            >
            <el-button @click="handleClose()" v-preventReClick size="large">{{
              closeTitle
            }}</el-button> -->
          </slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "bidDialog",
  props: {
    title: {
      type: String,
      default: "标题",
    },
    width: {
      type: String,
      default: "35%",
    },
    top: {
      type: String,
      default: "15vh",
    },
    visible: {
      type: Boolean,
      default: false,
    },
    footVisible: {
      type: Boolean,
      default: true,
    },
    okTitle: {
      type: String,
      default: "确认",
    },
    closeTitle: {
      type: String,
      default: "取消",
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    showOk: {
      type: Boolean,
      default: true,
    }
  },
  setup(props, context) {
    let { emit } = context;
    const handleClose = () => {
      emit("close");
    };
    const handleOk = () => {
      emit("ok");
    };
    return {
      handleClose,
      handleOk,
    };
  },
};
</script>

<style scoped lang="less">
.dialog-fade-enter-active {
  animation: fade 0.4s;
}

.dialog-fade-leave-active {
  animation: fade 0.4s reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.dialog__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 2001;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog {
  // position: relative;
  // margin: 0 auto;
  border-radius: 4px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.6);
  box-sizing: border-box;
  // width: 30%;
}

.dialog__headerbtn {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 16px;
}

.button:first-child {
  margin-right: 20px;
}

.el-icon-close {
  color: #909399;
}

.dialog__title {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 33px;

}

.dialog__header {
  border-bottom: 1px solid #E6E8EC;
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-close {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
}

.dialog__body {
  padding: 23px 40px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}

.dialog__footer {
  padding-right: 50px;
  padding-bottom: 40px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  span {
    width: 96px;
    height: 40px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    cursor: pointer;
  }

  &>span:nth-child(1) {
    color: #333333;
    border: 1px solid #B7BBC0;
    margin-right: 17px;
  }

  &>span:nth-child(2) {
    background: #367CC5;
    color: #FFFFFF;
  }
}

.confirm {
  background-color: #559eff;
  color: #fff;
}

.cancel {
  border: 1px solid #ccc;
}

.dialog_button {
  display: inline-block;
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 4px;
  cursor: pointer;
}

.dialog_button:first-child {
  margin-right: 100px;
}
</style>
